import React from "react";
import { Dropdown } from "react-bootstrap";
import avatar from "../../assets/images/avatar.png";
import { Button } from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import { useContext, useEffect } from "react";
import { User } from "lucide-react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/svg/ciceroAI-logo.svg";

const Header = () => {
  const { user, logout, loading: isLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  // if user is logged in, and is in the login page, redirect to the dashboard  
  useEffect(() => {
    if (user && !isLoading) {
      navigate("/upload-documents", { replace: true });
    }
  }, [user]);

  return (
    <header className="d-flex justify-content-between align-items-center p-3 bg-primary text-white shadow-lg">
      {/* Left Section: Logo and Title */}
      <div className="d-flex align-items-center">
        <img src={logo} alt="Logo" style={{ width: 40, height: 40, marginRight: 10 }} />
        <h4 className="m-0">Document Drafter </h4>
      </div>

      {/* Center Section: Navigation Links (optional, can be added as needed) */}
      <nav className="d-flex gap-3">
        <a href="http://quickfiller.ciceroai.net/due-dillegence-tool" className="text-white text-decoration-none">Due Diligence</a>
        <a href="http://ciceroai.net/chat" className="text-white text-decoration-none">Legal Research</a>
        {/* <a href="/#/upload-documents" className="text-white text-decoration-none">Upload Documents</a> */}
        {/* Add more navigation links here */}
      </nav>

      {/* Right Section: User Dropdown */}
      {
        user && !isLoading && (
          <div className="d-flex align-items-center gap-2">

            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                className="d-flex align-items-center text-white"
              >
                <User size={20} style={{ marginRight: "10px" }} />
                <span>{user?.name ?? "Log In"}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* Add profile link if needed */}
                {/* <Dropdown.Item href="#/profile">Profile</Dropdown.Item> */}
                {user && !isLoading && (
                  <Dropdown.Item href="/#/" onClick={logout}>
                    Logout
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )
      }
    </header>
  );
};

export default Header;
