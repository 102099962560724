import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // To handle loading state

  useEffect(() => {
    fetchUser(); // Fetch user when app loads
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`, { email, password });
    localStorage.setItem('documentdrafter_auth_token', response.data.access_token);
    fetchUser();
    window.location='/#/upload-documents';
   
  };

  const fetchUser = async () => {
    const token = localStorage.getItem('documentdrafter_auth_token');
    setLoading(true);
    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error("Failed to fetch user:", error);
        localStorage.removeItem('documentdrafter_auth_token'); // Clear token if fetch fails
        setUser(null);
      }
    }
    setLoading(false); // Set loading to false after fetch attempt
  };

  const logout = async () => {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/logout`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('documentdrafter_auth_token')}`,
      },
    });
    localStorage.removeItem('documentdrafter_auth_token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, fetchUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;