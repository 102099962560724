import React, { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../context/AuthContext';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Check, X, Loader2 } from 'lucide-react';
import { PuffLoader } from 'react-spinners'

import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const UploadDocument = () => {
  const navigate = useNavigate();
  const [pdfList, setPdfList] = useState([]);
  const [pdfFormList, setPdfFormList] = useState([]);
  const [pdfID, setPdfID] = useState('');
  const { user, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [processingMethod, setProcessingMethod] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [processingStatus, setProcessingStatus] = useState('');

  const [showStatusSidebar, setShowStatusSidebar] = useState(false);
  const [processedDocs, setProcessedDocs] = useState([]);
  const pollingIntervalRef = useRef(null);

  const [allCompleted, setAllCompleted] = useState(false);


  // Existing useEffect for fetching PDF list
  useEffect(() => {
    const fetchPdfList = async () => {
      setLoading(true);
      setError('');
      try {
        const token = localStorage.getItem('documentdrafter_auth_token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/pdfs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPdfList(response.data);
      } catch (error) {
        setError('Failed to fetch documents. Please try again.');
        console.error('Error fetching PDFs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPdfList();
  }, []);

  const [formData, setFormData] = useState({});

  const handleInputChange = (e, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: e.target.value,
    }));
  };

  const handleFileUpload = (e) => {
    setUploadedFiles(Array.from(e.target.files));
  };

  const handleProcessingMethodSelect = (method) => {
    setProcessingMethod(method);
    if (method === 'ai') {
      setShowModal(true);
    }
  };

  // Function to fetch document processing status
  const fetchProcessingStatus = async () => {
    try {
      const token = localStorage.getItem('documentdrafter_auth_token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/pdf/processed-documents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProcessedDocs(response.data.document_processed);

      const allProcessed = response.data.document_processed.every(
        doc => doc.server_status === 'processed'
      );
      
      if (allProcessed) {
        console.log('All documents processed, clearing interval:', pollingIntervalRef.current);
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
        }
        setAllCompleted(true);
        setTimeout(() => {
          setShowStatusSidebar(false);
          setProcessingStatus('All documents processed!');
          window.location.href = `/#/upload-documents?pdf_id=${pdfID}`;
        }, 3000);
      }
     
    } catch (error) {
      console.error('Error fetching status:', error);
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    }
  };

  const handleModalSubmit = async () => {
    if (uploadedFiles.length === 0) {
      setError('Please upload at least one document');
      return;
    }

    setLoading(true);
    setError('');

    const formData = new FormData();
    uploadedFiles.forEach(file => {
      formData.append('files[]', file);
    });

    try {
      const token = localStorage.getItem('documentdrafter_auth_token');
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/process-training-data/${pdfID}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setShowModal(false);
      setProcessingStatus('Documents are being processed. AI will analyze and fill in the answers.');
      setShowStatusSidebar(true);

      // Start polling and store the interval ID in the ref
      pollingIntervalRef.current = setInterval(fetchProcessingStatus, 10000);
    
      // Fetch status immediately
      fetchProcessingStatus();


      // Update formData with AI-generated values if available
      if (response.data.fields) {
        setFormData(response.data.fields);
      }
    } catch (error) {
      setError('Error processing documents. Please try again.');
      console.error('Error processing documents:', error);
    } finally {
      setLoading(false);
    }
  };

  const uploadProcessedPdf = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('documentdrafter_auth_token');
    const formDataArray = Object.entries(formData).map(([name, value]) => ({ name, value }));

    setLoading(true);
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/processdoc/${pdfID}`, {
        method: 'POST',
        body: JSON.stringify({ fields: formDataArray }),
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const updatedData = await response.json();
        const filePath = `${process.env.REACT_APP_BACKEND_URL}/storage/` + updatedData.file_path;
        redirectToDownloadPage(filePath, updatedData.file_name);
      } else {
        setError('Error processing PDF. Please try again.');
      }
    } catch (error) {
      setError('Network error. Please check your connection and try again.');
      console.error('Error during submission:', error);
    } finally {
      setLoading(false);
    }
  };

  const redirectToDownloadPage = (filePath, fileName) => {
    const encodedFilePath = encodeURIComponent(filePath);
    navigate(`/download?file=${encodedFilePath}&file_name=${fileName}`);
  };

  // Initialize form data when pdfFormList changes
  useEffect(() => {
    if (pdfFormList?.annotations?.length) {
      const initialFormData = {};
      pdfFormList.annotations.forEach((field) => {
        if (field && Array.isArray(user?.uploaded_documents_answers)) {
          const existingValue = user?.uploaded_documents_answers?.find((doc) => doc.formFieldName === field.formFieldName)?.formFieldNameValue || '';
          
          initialFormData[field.formFieldName] = existingValue;
        }
      });
      setFormData(initialFormData);
    }
  }, [pdfFormList, user?.uploaded_documents_answers]);

  
  const location = useLocation();
  useEffect(() => {
    
    const query = new URLSearchParams(location.search);
    const paramField = query.get('pdf_id');

    const userSelectedDoc = user?.uploaded_documents_answers?.[0]
    const userSelectedDocPdfId = userSelectedDoc?.pdf_id;
    const selectedDoc = pdfList.find((doc) => parseInt(doc.id) === parseInt(paramField));

    if(paramField && (userSelectedDocPdfId == paramField) && selectedDoc != undefined)
    {
      setPdfID(paramField);
  
      if((userSelectedDocPdfId == paramField))
      {
        setPdfFormList(JSON.parse(selectedDoc?.metadata));
        handleProcessingMethodSelect('manual')
      }
    }
  
  }, [user,location.search,pdfList])

  const handleBackButton = () => {

    const query = new URLSearchParams(location.search);
    const paramField = query.get('pdf_id');

    setPdfID(paramField);
    setProcessingMethod('');
    setProcessingStatus('');
  }

  // Cleanup polling on component unmount
  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, []);

  return (
    <div className="d-flex">
      {/* Status Sidebar */}

      {showStatusSidebar && (
        <div className="border-end p-4" style={{ width: '300px' }}>
          <h6 className="mb-4">Processing Status</h6>
          {processedDocs.map((doc, index) => (
            <div key={index} className="d-flex align-items-center justify-content-between mb-3">
              <span className="text-truncate pe-3" style={{ maxWidth: '80%' }}>{doc.file_name}</span>
              <div className="flex-shrink-0">
                {doc.status === 'pending' && (
                  <PuffLoader size={20} />
                )}
                {doc.status === 'completed' && (
                  <Check className="text-success" size={16} />
                )}
                {doc.status === 'failed' && (
                  <X className="text-danger" size={16} />
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={`container ${showStatusSidebar ? 'ms-4' : 'w-75'} mt-4 mb-5`}>

        
        {error && <Alert variant="danger">{error}</Alert>}
        {processingStatus && <Alert variant="info">{processingStatus}</Alert>}

        <div className="mb-4">
          <label htmlFor="categories" className="form-label fw-bold">
            Select Document
          </label>
          <select
            id="categories"
            className="form-select"
            onChange={(e) => {
              setPdfID(e.target.value);
              setProcessingMethod('');
              setProcessingStatus('');
              const selectedDoc = pdfList.find((doc) => parseInt(doc.id) === parseInt(e.target.value));
              const userSelectedDoc = user?.uploaded_documents_answers.annotations?.[0]
              const userSelectedDocPdfId = userSelectedDoc?.pdf_id;

              if(userSelectedDocPdfId == e.target.value)
              {
                  setPdfFormList(user?.uploaded_documents_answers);
              }else{

                if (selectedDoc) {
                  setPdfFormList(JSON.parse(selectedDoc.metadata));
                }
              }

            }}
            value={pdfID ?? ''}
          >
            <option value="">Select Document</option>
            {pdfList.map((doc) => (
              <option key={doc.id} value={doc.id}>
                {doc.file_name}
              </option>
            ))}
          </select>
        </div>

        {(pdfID && !processingMethod) && (
          <div className="mb-4">
            <h5>Choose Processing Method:</h5>
            <div className="d-grid  gap-2">
              <Button 
                variant="outline-primary" 
                onClick={() => handleProcessingMethodSelect('manual')}
              >
                Answer The Questions Manually
              </Button>
              <div className='text-center'> OR </div> 
              <Button 
                variant="outline-primary" 
                onClick={() => handleProcessingMethodSelect('ai')}
              >
                AI Analysis with Document Upload
              </Button>
            </div>
          </div>
        )}
        {/* Document Upload Modal */}
        <Modal show={showModal} onHide={() => {
            if(!loading)
            {
              setShowModal(false)
              setProcessingMethod('');
            }
            
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Training Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Upload Documents for AI Analysis</Form.Label>
              <Form.Control 
                type="file" 
                multiple 
                onChange={handleFileUpload}
                accept=".pdf"
              />
            </Form.Group>
            <small className="text-muted">
              Upload relevant documents for AI to analyze and fill in the answers automatically.
            </small>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" 
               disabled={loading}
            onClick={() => {
              setShowModal(false)
              setProcessingMethod('');
            }}>
              Close
            </Button>
            <Button 
              variant="primary" 
              onClick={handleModalSubmit}
              disabled={loading || uploadedFiles.length === 0}
            >
              {loading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                'Process Documents'
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Form Fields */}
        {(processingMethod == 'manual' || processingStatus) && (
          <Form onSubmit={uploadProcessedPdf}>
            {pdfFormList && pdfFormList.annotations && pdfFormList.annotations.length !== 0 ? (
              pdfFormList.annotations.map((field) => {
                if (field !== null) {
                  return (
                    <Form.Group className="mb-3" key={field.formFieldName}>
                      <Form.Label>{field.formFieldName}</Form.Label>
                      <Form.Control
                        type={field.type}
                        defaultValue={
                          Array.isArray(user?.uploaded_documents_answers)
                            ? user?.uploaded_documents_answers.find((doc) => doc.formFieldName == field?.formFieldName)?.formFieldNameValue
                            : ''
                        }
                        onChange={(e) => handleInputChange(e, field?.formFieldName)}
                      />
                    </Form.Group>
                  );
                }
                return null;
              })
            ) : (
              <p className="text-muted">No form fields available for this document.</p>
            )}

      
            <Button
              variant="primary"
              type="submit"
              disabled={loading || !pdfID}
              className="w-100"
            >
              {loading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                'Submit'
              )}
            </Button>
            <Button
              variant="outline-primary"
              className="w-100 mt-2"
              onClick={handleBackButton}
            >
              Back
            </Button>
          
          </Form>
        )}
      </div>
    </div>
  );
};

export default UploadDocument;