import React from 'react';
import { useSearchParams } from 'react-router-dom';

const DownloadPage = () => {
    const [searchParams] = useSearchParams();
    const filePath = searchParams.get('file'); // Get file path from query parameter
    const fileName = searchParams.get('file_name') || 'XYZ'; // Default to 'XYZ' if no name provided

    // Handle file download
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = filePath;
        link.download = filePath.split('/').pop(); // Extract filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Error view if no file path is provided
    if (!filePath) {
        return (
            <div style={styles.errorContainer}>
                <h2 style={styles.errorTitle}>Error</h2>
                <p style={styles.errorMessage}>File not found. Please try again.</p>
            </div>
        );
    }

    // Main download view
    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.title}>Your document is ready!</h2>
                <p style={styles.subtitle}>
                    Please download your <strong>{fileName}</strong> document.
                </p>
                <button onClick={handleDownload} style={styles.downloadButton}>
                    Download
                </button>
            </div>
        </div>
    );
};

// CSS-in-JS Styles
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f4f7f6',
    },
    card: {
        backgroundColor: '#ffffff',
        padding: '30px 40px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        maxWidth: '400px',
        width: '100%',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#333333',
    },
    subtitle: {
        fontSize: '16px',
        marginBottom: '20px',
        color: '#555555',
    },
    downloadButton: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#007bff',
        color: '#ffffff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    downloadButtonHover: {
        backgroundColor: '#0056b3',
    },
    errorContainer: {
        padding: '20px',
        textAlign: 'center',
    },
    errorTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#dc3545',
    },
    errorMessage: {
        fontSize: '16px',
        color: '#6c757d',
    },
};

export default DownloadPage;
