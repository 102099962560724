import { HashRouter  as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './components/Login';
// import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import PdfViewer from './components/PdfViewer';
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar"; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Documents from "./pages/Documents"; 
import UploadDocument from "./pages/UploadDocument"; 
import DownloadPage from "./pages/DownloadPage";
import Dashboard from "./pages/Dashboard"; 

import Register from './components/Register';

function App() {
  return (
    <AuthProvider>
      <Router>
      <div className="d-flex">
      {/* <Sidebar /> */}
      <div className="content d-flex flex-column">
      <Header />
      
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/upload-documents" element={<ProtectedRoute component={ <UploadDocument />} />} />
          <Route path="/download" element={<DownloadPage />} />

          {/* <Route path="/dashboard" element={<ProtectedRoute component={<Dashboard />} />} /> */}
          <Route path="/register" element={<Register />} />
          
          
          {/* Dynamic route for loading PDF based on ID */}
          <Route path="/pdf/:id" element={<ProtectedRoute component={<PdfViewer />} />} />
        </Routes>
      
      </div>
    </div>
    </Router>
    </AuthProvider>
  );
}

export default App;